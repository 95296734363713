<template>
	
<FormKitLazyProvider config-file="true" :default-config="false">
<FormKit type="password" suffix-icon="eye" @suffix-icon-click="handleIconClick" >
		<template #suffix>
			<slot name="suffix"></slot>
		</template>
		<template #help>
			<slot name="help"></slot>
		</template>
	</FormKit>
</FormKitLazyProvider>

</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
const handleIconClick = (node: any, e: any) => {
	node.props.suffixIcon = node.props.suffixIcon === 'eye' ? 'eyeClosed' : 'eye'
	node.props.type = node.props.type === 'password' ? 'text' : 'password'
}
</script>